//Updated 2023



//used in: navbar.component.html "nav-buttons"
function sidebar_content_show(event) {

  var clicked_ele_id = event.target.id;

  console.log(clicked_ele_id);

  //change color of clicked navbar_btn
  var navbar_buttons = document.getElementsByClassName("navbar_btn");
  

  for (let i = 0; i < navbar_buttons.length; i++) {

    document.getElementById(navbar_buttons[i].id).style.backgroundColor="beige";
  }

  

  // hide all  categories in sidebar.component.html
  var ele_s = document.getElementsByClassName("sidebar_content");
  
  //console.log(ele_s);

  for (let i = 0; i < ele_s.length; i++ ) {

    //hide per id === ALL
    document.getElementById(ele_s[i].id).classList.add("sidebar_hide");
  }

  //re-hide the  choosen categorie by switch: clicked_ele_id
  //btn_nav_HOME  sidebar_home
  //btn_nav_VBA   sidebar_vba
  //btn_nav_WEB   sidebar_web
  
  switch (clicked_ele_id) {
    //nav
    case "btn_nav_home":
      document.getElementById("sidebar_home").classList.remove("sidebar_hide");
      document.getElementById("btn_sidebar_home").style.backgroundColor = "lightgray";
      
      break;
    case "btn_nav_vba":
      document.getElementById("sidebar_vba").classList.remove("sidebar_hide");
      document.getElementById("btn_sidebar_vba").style.backgroundColor = "lightgray";

      break;
    case "btn_nav_web":
      document.getElementById("sidebar_web").classList.remove("sidebar_hide");
      document.getElementById("btn_sidebar_web").style.backgroundColor = "lightgray";

      break;
    //sidebar
    case "btn_sidebar_home":
      document.getElementById("sidebar_home").classList.remove("sidebar_hide");
      document.getElementById("btn_nav_home").style.backgroundColor = "lightgray";

      break;
    case "btn_sidebar_vba":
      document.getElementById("sidebar_vba").classList.remove("sidebar_hide");
      document.getElementById("btn_nav_vba").style.backgroundColor = "lightgray";

      break;
    case "btn_sidebar_web":
      document.getElementById("sidebar_web").classList.remove("sidebar_hide");
      document.getElementById("btn_nav_web").style.backgroundColor = "lightgray";

      break;


       
  }
  
  //color this button
  document.getElementById(clicked_ele_id).style.backgroundColor = "lightgray";


  //Show sidebar
  document.getElementById("sidebar_frame_flex").style.display ="block";
  

}

//OF Update 29.10.2023
//used in:
//sidebar.component.html: btn_sidebar_show_toggle
function sidebar_show_toggle() {

  console.log("sidebar_show_toggle()");

  var ele = document.getElementById("sidebar_frame_flex");
  
  //console.log(ele);

  //show
  if (ele.style.display === "none") {

    ele.style.display = "block";


    //move  content: "page"
    if (screen.width > 756) {

      document.getElementById("content_frame").style.marginLeft = "230px";

    }

     

  //hide
  } else {

    ele.style.display = "none";

    //move  content: "page"
    if (screen.width > 756) {

      document.getElementById("content_frame").style.marginLeft = "35px";

    }
  }


}
