// call in: index.html:  HEAD: <script src="assets/js/cookiepopup.js"></script> 
//auch angemeldet in: angular.json: "scripts"
////////////////////////////
// C O O K I E S///////////
///////////////////////////
// 1st Party cookie
//SameSite=Strict = 1stparty cookie, Nur von der senden Seite an den Nutzer und zurück
// secure: Nur mit HTTP_S Domain :) aber http only mal googeln

//Dieser Javascript wird nur beim betreten der Webseite einmal geladen (ANGULAR)!!!

console.log("cookie check started")
var now = new Date();
var time = now.getTime();
var maxage = 7*24*60*60*1000;
var expireTime = time + maxage;
now.setTime(expireTime);
var expireDate = now.toUTCString();

cookieStorage = {
getItem: (key) => {
        cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc,[key, value])=>({ ...acc, [key.trim()]: value}),{});
        return cookies[key];
    },

    setItem: (key, value) => {
        //   `` niemals linebreaks in cookie strings!
        //document.cookie = `${key}=${value};expires='+expireDate+';SameSite=Strict;secure;path='/'`; 
        var cookiestring= key +"=" + value +
         "; expires=" + expireDate + "; path='/';SameSite=Strict;secure" ; 
        document.cookie = cookiestring;

        console.log(document.cookie +' ExpireDate: '+ expireDate);
    },

};     
       

storageType = cookieStorage;
consentPropertyName ='ofdev_consent';
 // !
shouldShowPopup = () => !storageType.getItem(consentPropertyName)
    if(storageType.getItem(consentPropertyName)){
        console.log("cookie vorhanden");
    }
saveToStorage = () => storageType.setItem(consentPropertyName, true);

window.onload = () => {
    
    const consentPopup = document.getElementById('ofdev_consent');
    const acceptBtn = document.getElementById('acceptBtn');
    const closeBtn = document.getElementById('closeBtn');
    
    //accept btn
   const acceptFn = _event =>{
        saveToStorage(storageType);
        consentPopup.classList.add('hidden');
    };
    
    acceptBtn.addEventListener('click', acceptFn);
 
    //close btn
    const closeFn = _event =>{
        consentPopup.classList.add('hidden');
    }

    closeBtn.addEventListener('click',closeFn);
    
    // Wenn KEIN Cookie gefunden hier: storageType == false dann Popup durch remove hidden!
    if (shouldShowPopup(storageType)){
       consentPopup.classList.remove('hidden');
        console.log("kein cookie gefunden");
    }



};

function cookieremove(){
    
    function removeItem(sKey, sPath, sDomain) {
        
        document.cookie = encodeURIComponent(sKey) + 
                      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + 
                      (sDomain ? "; domain=" + sDomain : "") + 
                      (sPath ? "; path=" + sPath : "")+
                      ";SameSite=Strict;Secure;";
    
                    
        console.log("cookiePOPUP  gelöscht")          
    }


    removeItem('ofdev_consent','/','')
    

}
